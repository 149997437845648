class ContactDataTabs {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.tabEls = this.root.querySelectorAll(
            '[data-contact-data-tabs-tab]'
        );
        this.listEls = this.root.querySelectorAll(
            '[data-contact-data-tabs-list]'
        );
        this.detailEls = this.root.querySelectorAll(
            '[data-contact-data-tabs-detail]'
        );

        return true;
    }

    init() {
        this.setTabs();
        this.setVideos();
    }

    setTabs() {
        if (!this.tabEls) return;

        this.onTab = this.onTabClick.bind(this);
        this.tabEls.forEach(item => item.addEventListener('click', this.onTab));
    }

    setVideos() {}

    onTabClick(event) {
        const { currentTarget } = event;
        const index = parseInt(
            currentTarget.getAttribute('data-contact-data-tabs-tab')
        );
        const listEl = this.root.querySelector(
            `[data-contact-data-tabs-list="${index}"]`
        );
        const detailEl = this.root.querySelector(
            `[data-contact-data-tabs-detail="${index}"]`
        );

        this.resetItems(this.tabEls);
        this.resetItems(this.listEls);
        this.resetItems(this.detailEls);

        currentTarget.classList.add('-active');
        if (listEl) listEl.classList.add('-active');
        if (detailEl) detailEl.classList.add('-active');
    }

    resetItems(items) {
        items.forEach(item => item.classList.remove('-active'));
    }
}

function initContactDataTabs() {
    const sections = document.querySelectorAll('[data-contact-data-tabs]');
    if (!sections) return;

    sections.forEach(item => new ContactDataTabs(item));
}

if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !document.documentElement.doScroll)
) {
    initContactDataTabs();
} else {
    document.addEventListener('DOMContentLoaded', initContactDataTabs);
}
